

<div class="input-outer-wrap" [class.editable]="editable" [class.ng-invalid]="FC?.invalid" [class.ng-dirty]="FC?.dirty">

  <div class="input-inner-wrap">

    <label>
      {{ label }}
    </label>

    <ng-content>
      
    </ng-content>
  </div>

  <div *ngIf="editable" class="input-actions-wrap">

    <button
      pButton
      icon="pi pi-pencil"
      class="p-button-rounded p-button-text p-button-plain edit-btn">
    </button>

    <button
      (click)="onDelete()"
      pButton
      icon="pi pi-trash"
      class="p-button-rounded p-button-text p-button-plain delete-btn">
    </button>

  </div>

</div>