import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { UsersService } from 'src/app/shared/services/entities/users/users.service';
import { finalize, Subscription, take } from 'rxjs';
import { AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUser } from 'src/app/shared/models/user/user.model';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { Currency } from 'src/app/shared/enums/price/currencies.enum';
import { ICreateCustomerCreditCallableData, IPaymentsByCashCallableData, ITransfersPair, TransfersService } from 'src/app/shared/services/entities/transfers/transfers.service';
import { ITransfer } from 'src/app/shared/models/transfers/transfer.model';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { ListboxModule } from 'primeng/listbox';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { OrgPaymentsStoreService } from 'src/app/shared/services/store/org-payments-store.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SelectItem } from 'primeng/api';
import { UserStoreService, IUserState } from 'src/app/shared/services/store/user-store.service';
import { RecordPaymentModalType } from 'src/app/shared/services/modal.service';
import { TranslateModule } from '@ngx-translate/core';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';

interface IRecordPaymentForm {
  selectedUser: FormControl<IUser | null>;
  usersQuery: FormControl<{ label: string }>;
  addCredit: FormControl<boolean | null>;
  note: FormControl<string | null>;
  amount?: FormControl<number | null>;
  currency?: FormControl<Currency | null>;
  selectedUnpaidTransfer: FormControl<ITransfer | null>;
};

@Component({
  selector: 'app-record-payment-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, AutoCompleteModule, InputNumberModule, DropdownModule, ProgressSpinnerModule, ListboxModule, TagModule, InputTextareaModule, InputMaskModule, SelectButtonModule,
    ModalHeaderComponent, PricePipe, LocalizedDatePipe, TranslateModule, NumberInputDirective
  ],
  templateUrl: './record-payment-modal.component.html',
  styleUrls: ['./record-payment-modal.component.scss']
})
export class RecordPaymentModalComponent extends Modal implements OnInit, OnDestroy, AfterViewInit {
  RecordPaymentModalType = RecordPaymentModalType;

  @Input() user?: IUser;
  @Input() orderId?: number | null;
  @Input() userName?: string;
  @Input() modalType?: RecordPaymentModalType;
  @Input() unpairedIncomeTransferId?: number;

  userSuggestions: IUser[] = [];
  unpaidTransfers: (ITransfer & { unpaid: IPrice })[] = [];

  currencyOptions: SelectItem[] = Object.values(Currency).map((currency) => ({ value: currency }));
  addCreditOptions: SelectItem[] = [
    {
      value: false,
      label: 'admin.payments.record-payment-modal.credit-option.pay-order.label'
    },
    {
      value: true,
      label: 'admin.payments.record-payment-modal.credit-option.transfer-to-credit.label'
    }
  ];

  form = new FormGroup<IRecordPaymentForm>({
    selectedUser: new FormControl(null),
    usersQuery: new FormControl({ label: '' }, { nonNullable: true }),
    addCredit: new FormControl(null, { validators: [Validators.required] }),
    note: new FormControl(null),
    selectedUnpaidTransfer: new FormControl(null, { validators: [ Validators.required ] }),
  });

  userState: IUserState | undefined;
  sending: boolean = false;
  fetchingTransfers: boolean = false;
  subs: Subscription[] = [];

  @ViewChild('queryInput') queryInput?: AutoComplete;

  constructor(
    private usersService: UsersService,
    private selectedOrgService: SelectedOrgService,
    private transfersService: TransfersService,
    private utilsService: UtilsService,
    private orgPaymentsStore: OrgPaymentsStoreService,
    private userStore: UserStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.modalType === RecordPaymentModalType.RECORD_PAYMENT) {
      this.form.addControl('amount', new FormControl(null, { validators: [ Validators.required ] }));
      this.form.addControl('currency', new FormControl(this.currencyOptions[0].value, { nonNullable: true, validators: [Validators.required] }));
    }
    this.userState = this.userStore.state;
    this.userState.user$.pipe(take(1)).subscribe((user) => {
      this.noteFC.patchValue(`${user?.name} ${user?.surname}`);
    });
    if (this.orderId && this.user) {
      this.form.controls.usersQuery.setValue({ label: `${this.user.name} ${this.user.surname}` });
      this.form.controls.selectedUser.setValue(this.user);
      this.form.controls.addCredit.setValue(false);
      const orgId = this.selectedOrgService.getCurrentValue();
      if (!orgId) return;
      this.fetchingTransfers = true;
      this.transfersService.getUserOrgUnpaid({
        organizationId: orgId, userId: this.user.id, orderId: this.orderId
      }).pipe(
        take(1),
        finalize(() => this.fetchingTransfers = false)
      ).subscribe({
        next: (res) => {
          this.unpaidTransfers = res;
          if (res.length > 0) {
            this.form.controls.selectedUnpaidTransfer.setValue(res[0]);
          }
        }
      });
    }
    this.watchForm();
    if (this.userName) {
      this.form.controls.usersQuery.setValue({ label: this.userName }, { onlySelf: true, emitEvent: true });
    }
  }

  ngAfterViewInit(): void {
    if (this.queryInput) {
      if (!this.user) this.queryInput.inputEL?.nativeElement.focus();
    }
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid || this.sending) {
      console.error('invalid');
      return;
    }

    const formVals = this.form.getRawValue();
    if ((!formVals.selectedUnpaidTransfer && !formVals.addCredit) || (this.modalType === RecordPaymentModalType.RECORD_PAYMENT && (!formVals.amount || !formVals.currency))) {
      console.error('invalid');
      return;
    }

    switch (this.modalType) {
      case RecordPaymentModalType.PAIR_PAYMENT:
        if (!this.unpairedIncomeTransferId) {
          console.error('Missing unpairedIncomeTransferId!');
          return;
        }
        let pairTransferData: ITransfersPair;
        if (formVals.addCredit) {
          pairTransferData = {
            adminNote: formVals.note,
            unpairedIncomeTransferId: this.unpairedIncomeTransferId,
            userId: formVals.selectedUser!.id,
            toCredit: true
          };
        } else {
          pairTransferData = {
            adminNote: formVals.note,
            unpairedIncomeTransferId: this.unpairedIncomeTransferId,
            userId: formVals.selectedUser!.id,
            unpaidTransferId: formVals.selectedUnpaidTransfer!.id
          };
        }
        this.sending = true;
        this.orgPaymentsStore.pairTransfer(pairTransferData).pipe(
          take(1),
          finalize(() => this.sending = false)
        ).subscribe({
          next: () => {
            this.close();
          }
        });
        break;
      case RecordPaymentModalType.RECORD_PAYMENT:
        if (formVals.addCredit) {
          const organizationId = this.selectedOrgService.getCurrentValue();
          if (!organizationId) {
            console.error('missing selected organization');
            return;
          };
          const data: ICreateCustomerCreditCallableData = {
            adminNote: formVals.note,
            amount: formVals.amount!,
            currency: formVals.currency!,
            userId: formVals.selectedUser!.id,
            organizationId: organizationId
          };
          this.sending = true;
          this.orgPaymentsStore.createCustomerCredit(data).pipe(
            take(1),
            finalize(() => this.sending = false)
          ).subscribe({
            next: (res) => {
              this.close();
            }
          });
        } else {
          const data: IPaymentsByCashCallableData = {
            unpaidTransferId: formVals.selectedUnpaidTransfer!.id,
            paidAmount: formVals.amount!,
            adminNote: formVals.note
          };
          this.sending = true;
          this.orgPaymentsStore.createCashPayments([data]).pipe(
            take(1),
            finalize(() => this.sending = false)
          ).subscribe({
            next: (res) => {
              this.close();
            }
          });
        }
        break;
      default:
        break;
    }
  }

  private watchForm() {
    this.subs.push(
      this.usersQueryFC.valueChanges.subscribe(v => {
        this.selectedUserFC.setValue(null);
        this.unpaidTransfers = [];
      }),

      this.selectedUserFC.valueChanges.subscribe(u => {
        if (u) {
          const orgId = this.selectedOrgService.getCurrentValue();
          if (!orgId) return;
          // get unpaid transfers
          this.fetchingTransfers = true;
          this.transfersService.getUserOrgUnpaid({
            organizationId: orgId, userId: u.id
          }).pipe(
            take(1),
            finalize(() => this.fetchingTransfers = false)
          ).subscribe({
            next: (res) => {
              this.unpaidTransfers = res;
            }
          });
        }
      }),

      this.selectedUnpaidTransferFC.valueChanges.subscribe((transfer) => {
        if (transfer) {
          this.currencyFC?.patchValue(transfer.currency);
        }
      }),

      this.addCreditFC.valueChanges.subscribe((addCredit) => {
        if (addCredit) {
          this.currencyFC?.enable();
          this.selectedUnpaidTransferFC.removeValidators(Validators.required);
          this.selectedUnpaidTransferFC.updateValueAndValidity();
        } else {
          this.currencyFC?.disable();
          if (this.selectedUnpaidTransferFC.value) {
            this.currencyFC?.patchValue(this.selectedUnpaidTransferFC.value.currency);
          }
          this.selectedUnpaidTransferFC.addValidators(Validators.required);
          this.selectedUnpaidTransferFC.updateValueAndValidity();
        }
      })
    );
  }

  whisper(e: { originalEvent: Event, query: string }) {
    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return;
    this.usersService.whisper({
        query: e.query,
        organizationIdCustomer: orgId
    }).pipe(
      take(1),
    ).subscribe({
      next: (res) => {
        this.userSuggestions = res.map(x => ({ ...x, label: `${x.name ?? ''} ${x.surname ?? ''} ${x.email}` }));
      }
    });
  }

  onUserSuggestionSelect(e: AutoCompleteSelectEvent) {
    this.selectedUserFC.setValue(e.value);
  }

  get selectedUserFC() {
    return this.form.controls['selectedUser'];
  }
  get usersQueryFC() {
    return this.form.controls['usersQuery'];
  }
  get selectedUnpaidTransferFC() {
    return this.form.controls['selectedUnpaidTransfer'];
  }
  get addCreditFC() {
    return this.form.controls['addCredit'];
  }
  get currencyFC() {
    return this.form.controls['currency'];
  }
  get noteFC() {
    return this.form.controls['note'];
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
