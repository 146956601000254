import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../../db.service';
import { Observable } from 'rxjs';
import { ICustomerCard } from 'src/app/pages/admin/org-admin/components/modals/customer-card-modal/admin-customer-card-modal.component';
import { IUser } from 'src/app/shared/models/user/user.model';
import { IOrder, IOrderFE } from 'src/app/shared/models/order/order-model';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { ICollectedUserData } from 'src/app/shared/models/collecting-data/collected-user-data.model';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { ISession } from 'src/app/shared/models/session-group/session.model';
import { ILocation } from 'src/app/shared/models/location/location.model';
import { ISingleSessionAttendanceFE } from 'src/app/shared/models/session-attendance/session-attendance.model';
import { ISessionGroup } from 'src/app/shared/models/session-group/session-group.model';
import { IInvoice } from 'src/app/shared/models/invoice/invoice.model';
import { ITransfer } from 'src/app/shared/models/transfers/transfer.model';
import { IOrganization } from 'src/app/shared/models/organization/organization.model';
import { IInvoiceProfile } from 'src/app/shared/models/invoice-profile/invoice-profile.model';
import { Language } from 'src/app/shared/enums/utils/languages.enum';
import { CustomersPersonsTableColumnItemType } from 'src/app/pages/admin/org-admin/reservations/reservation-table/custom-table-columns-modal/custom-table-column-item/custom-table-column-item.component';

export interface ICustomerCardGetByUserData {
  userId: number;
  orgId: number;

  include: ICustomerCardIncludeData;
};
export interface ICustomerCardIncludeData {
  orders?: boolean;
  reservations?: boolean;
  user?: boolean;
  credit?: boolean;
  userData?: boolean;
  attendance?: boolean;
}
export interface ICustomerCardResultData {
  user?: IUser;
  orders?: IOrderFE[];
  reservations?: IReservation[];
  credit?: IPrice[];
  userData: GetCustomDataResultType[];
  attendance?: IUserSessionGroupAttendanceOverview;
};

export type GetCustomDataResultType = ICollectedUserData & {
  id: number;
  title: string;
  value: any;
} & ({
  for: 'reservationCard';
  reservationId: number;
  description: string;
  link?: string;
} | {
  for: 'userCard';
});

export interface IUserSessionGroupAttendanceOverview {
  currentlyAttending: IUserSessionGroupCurrentlyAttendingAttendance[];
  excusedForReplacement: IUserSessionGroupExcusedForReplacementAttendance[];
};
export interface IUserSessionGroupCurrentlyAttendingAttendance {
  shopItem: IShopItem & { sessionGroup: ISessionGroup & { sessions: ISession[] } | null };
  sessionAttendances: ISingleSessionAttendanceFE[];
  reservation: IReservation & { order: IOrder | null };
}
export interface IUserSessionGroupExcusedForReplacementAttendance {
  shopItem: IShopItem;
  sessionAttendance: ISingleSessionAttendanceFE;
}


export interface ICallablesUserDataUpdate {
  userId: number;
  organizationId: number;

  data: {
    collectedUserDataId: number;
    userDataValue: any;
  }[];
}

export interface ICustomerWhisperData {
  query: string;

  orgId: number;
}

export enum ReceiptType {
  RECEIPT = 'RECEIPT',
  TAX_RECEIPT = 'TAX_RECEIPT'
}

interface ICallablesUsersCustomerGetPaymentDataData {
  trasferUuid: string;
  type: ReceiptType;
}

interface ICallablesCustomerPaymentData {
  order: IOrderFE | null;
  invoice: IInvoice | null;
  transfer: (ITransfer & {
    order: IOrder | null;
    organization: IOrganization & { invoiceProfile: IInvoiceProfile | null };
    user: IUser | null
  }) | null;
  proFormaNumber: string | null;
}

export interface ICustomersGetForOverviewData {
  orgId: number;

  filters?: {
    searchQuery?: string | null;
  };

  // pagination
  page: number;
  pageSize: number;
};
export interface ICustomersUsersData {
  user: IUser;
  dataMap: { [key in CustomersPersonsTableColumnItemType]?: any };
}
export interface ICustomersGetForOverviewResult {
  usersData: ICustomersUsersData[];
  totalRecords: number;
};

export interface ICallablesUsersCustomerCreateData {
  name: string;
  surname: string;
  email: string;
  preferredLanguage: Language;
  imgBase64?: string;

  orgId: number;
}

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private dbService: DbService,
  ) { }

  public createCustomer(data: ICallablesUsersCustomerCreateData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersCustomerCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getCustomerOverview(data: ICustomersGetForOverviewData): Observable<ICustomersGetForOverviewResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomersGetForOverview, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public whisper(data: ICustomerWhisperData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomersWhisper, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateCustomerData(data: ICallablesUserDataUpdate) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUserDataUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public getCustomerCard(data: ICustomerCardGetByUserData): Observable<ICustomerCardResultData> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomerCardGetByUser, data});
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getCustomerPaymentData(data: ICallablesUsersCustomerGetPaymentDataData): Observable<ICallablesCustomerPaymentData> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersCustomerGetPaymentData, data});
    return this.dbService.handleObs(obs, { allSilent: true });
  }
}
