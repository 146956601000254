import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { HiddenInputWrapComponent } from 'src/app/shared/components/hidden-input-wrap/hidden-input-wrap.component';
import { DateInputComponent } from 'src/app/shared/components/date-input/date-input.component';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { GetCustomDataResultType } from 'src/app/shared/services/entities/users/customer/customers.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';

export type ICustomDataFG = FormGroup<{
  [id: string]: FormControl<any>;
}>

@Component({
  selector: 'app-custom-data-inputs',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    SelectButtonModule, InputMaskModule, CheckboxModule,
    DateInputComponent, HiddenInputWrapComponent, InputNumberModule, NumberInputDirective
  ],
  templateUrl: './custom-data-inputs.component.html',
  styleUrls: ['./custom-data-inputs.component.scss']
})
export class CustomDataInputsComponent {
  DataType = DataType;

  @Input() customDataFG: ICustomDataFG | undefined;
  @Input() userData: GetCustomDataResultType[] | undefined;

  constructor(
    public utilsService: UtilsService
  ) {}

  triStateOptions: SelectItem[] = [
    {
      icon: 'pi pi-check text-xs',
      value: true
    },
    {
      icon: 'pi pi-times text-xs',
      value: false
    }
  ];

  getObjectValues(obj: any) {
    const vals = Object.values(obj);
    const out = [];
    for (let val of vals) {
      if (val) {
        if (typeof val === 'object') {
          vals.push(...this.getObjectValues(val));
        } else {
          out.push(val);
        }
      }
    }
    return out
  }

  getSmth(obj: any) {
    return this.getObjectValues(obj).join(', ');
  }
}
