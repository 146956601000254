

<ng-container *ngIf="customDataFG && userData">
  <ng-container [formGroup]="customDataFG">
    <ng-container *ngFor="let d of userData">
  
      <div *ngIf="customDataFG.controls[d.id] as FC" class="field">
  
        <app-hidden-input-wrap [FC]="FC" [editable]="!FC.disabled" [label]="d.title" class="">
          <ng-container [ngSwitch]="d.dataType">
        
            <ng-container *ngSwitchCase="DataType.BIRTHDATE">
              <app-date-input [justInput]="true" [FC]="FC"></app-date-input>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.DATE">
              <app-date-input [justInput]="true" [FC]="FC"></app-date-input>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.STRING">
              <input type="text" pInputText [formControl]="FC">
            </ng-container>
            <ng-container *ngSwitchCase="DataType.NUMBER">
              <p-inputNumber appNumberInput [formControl]="FC" />
            </ng-container>
            <ng-container *ngSwitchCase="DataType.CHECKBOX">
              <p-checkbox [binary]="true" [formControl]="FC"></p-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.CONSENT">
              <p-checkbox [binary]="true" [formControl]="FC"></p-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.PHONE_NUMBER">
              <input type="text" pInputText [formControl]="FC">
            </ng-container>
            <ng-container *ngSwitchCase="DataType.TRI_STATE_CHECKBOX">
              <p-selectButton [options]="triStateOptions" [formControl]="FC" optionLabel="icon" optionValue="value">
                <ng-template let-item pTemplate>
                  <i [class]="item.icon"></i>
                </ng-template>
              </p-selectButton>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.MULTISELECT">
              {{ d.value }}
            </ng-container>
            <ng-container *ngSwitchCase="DataType.BILLING_INFO">
              {{ getSmth(d.value) }}
            </ng-container>
            <ng-container *ngSwitchCase="DataType.CONTACT_ADDRESS">
              {{ getSmth(d.value) }}
            </ng-container>
            <ng-container *ngSwitchCase="DataType.SCROLLING_TEXTAREA">
              <p-checkbox [binary]="true" [formControl]="FC"></p-checkbox>
            </ng-container>
            <ng-container *ngSwitchDefault>
              input not implemented!, value: {{ d.value }}
              {{ utilsService.logError('Missing switch case '+d.dataType+' in CustomDataInputsComponent') }}
            </ng-container>
        
          </ng-container>
          
        </app-hidden-input-wrap>
        
  
      </div>
    </ng-container>
  </ng-container>
</ng-container>