import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, of, take, tap } from 'rxjs';
import { IReservationDetail } from 'src/app/pages/admin/org-admin/components/modals/reservation-detail-modal/reservation-detail-modal.component';
import { IReservationsCancelData, ReservationsService } from '../entities/reservations/reservations.service';
import { UtilsService } from '../utils.service';
import { IOrderItemPriceUpdateData, OrderItemsService } from '../entities/order-items/order-items.service';
import { ICallablesOffersCancelData, OffersService } from '../entities/offers/offers.service';

export interface IReservationDetailState {
  reservationDetail$: BehaviorSubject<IReservationDetail | null>;
  fetchingReservationDetail$: BehaviorSubject<boolean>;
  updatingReservationDetail$: BehaviorSubject<boolean>;

  updatingOrderItemPrice$: BehaviorSubject<boolean>;
};

@Injectable({
  providedIn: 'root'
})
export class ReservationDetailStoreService {

  private initState: IReservationDetailState = {
    reservationDetail$: new BehaviorSubject<IReservationDetail | null>(null),
    fetchingReservationDetail$: new BehaviorSubject<boolean>(false),
    updatingReservationDetail$: new BehaviorSubject<boolean>(false),

    updatingOrderItemPrice$: new BehaviorSubject<boolean>(false),

  };
  public state: IReservationDetailState = this.utilsService.initializeState(this.initState);

  constructor(
    private reservationsService: ReservationsService,
    private utilsService: UtilsService,
    private orderItemsService: OrderItemsService,
    private offersService: OffersService,
  ) { }

  public init(reservationId: number) {
    this.utilsService.resetState(this.initState, this.state);

    this.state.fetchingReservationDetail$.next(true);
    this.reservationsService.getForDetail({ reservationId }).pipe(
      take(1),
      finalize(() => this.state.fetchingReservationDetail$.next(false)),
    ).subscribe({
      next: (reservationDetail) => {
        this.state.reservationDetail$.next(reservationDetail);
      }
    })
  }
  public reinit() {
    const reservationId = this.state.reservationDetail$.value?.reservation.id;
    if (reservationId) {
      this.init(reservationId);
    }
  }

  public cancelOffer(data: ICallablesOffersCancelData) {
    this.state.updatingReservationDetail$.next(true);
    return this.offersService.cancel(data).pipe(
      take(1),
      tap(() => {
        this.reinit();
      })
    );
  }

  public cancel(data: IReservationsCancelData) {
    this.state.updatingReservationDetail$.next(true);
    return this.reservationsService.cancel(data).pipe(
      take(1),
      tap(() => {
        this.reinit();
      })
    );
  }

  public updateOrderItemPrice(d: IOrderItemPriceUpdateData) {
    this.state.updatingOrderItemPrice$.next(true);
    return this.orderItemsService.updatePrice(d).pipe(
      take(1),
      finalize(() => this.state.updatingOrderItemPrice$.next(false)),
      tap(() => {
        const r = this.state.reservationDetail$.value;
        if (r?.reservation.orderItem?.basePriceValue) r.reservation.orderItem.basePriceValue = d.basePriceValue;
        this.state.reservationDetail$.next(r);

      })
    );
  }
}
