import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { IUser } from 'src/app/shared/models/user/user.model';
import { IOrderFE } from 'src/app/shared/models/order/order-model';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabView, TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { Table, TableModule } from 'primeng/table';
import { CustomerReservationTableRowComponent } from './customer-reservation-table-row/customer-reservation-table-row.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CustomerCardOrderComponent } from './customer-card-order/customer-card-order.component';
import { ChipsModule } from 'primeng/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerCardInvoker, CustomerCardStoreService, ICustomerCardState } from 'src/app/shared/services/store/customer-card-store.service';
import { SkeletonModule } from 'primeng/skeleton';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { MagicCodesService } from 'src/app/shared/services/entities/magic-codes/magic-codes.service';
import { Subscription, finalize, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetCustomDataResultType, IUserSessionGroupAttendanceOverview } from 'src/app/shared/services/entities/users/customer/customers.service';
import { HiddenInputWrapComponent } from 'src/app/shared/components/hidden-input-wrap/hidden-input-wrap.component';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CustomerCardAttendanceComponent } from './customer-card-attendance/customer-card-attendance.component';
import { CustomerCardCustomerComponent } from './customer-card-customer/customer-card-customer.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserStoreService } from 'src/app/shared/services/store/user-store.service';
import { RoleType } from 'src/app/shared/enums/user/role-types.enum';
import { OrganizationStoreService } from 'src/app/shared/services/store/organization-store.service';
import { CustomerCardNotesComponent } from './customer-card-notes/customer-card-notes.component';
import { CustomerCardMessagesComponent } from './customer-card-messages/customer-card-messages.component';
import { CdkPortalOutlet, Portal, PortalModule, TemplatePortal } from '@angular/cdk/portal';

export interface ICustomerCard {
  user: IUser | undefined;
  orders: IOrderFE[] | undefined;
  reservations: IReservation[] | undefined;
  credit: IPrice[] | undefined;
  userData: GetCustomDataResultType[] | undefined;
  attendance: IUserSessionGroupAttendanceOverview | undefined;
};

export enum AdminCustomerCardTab {
  CUSTOMER = 'CUSTOMER',
  ORDERS = 'ORDERS',
  RESERVATIONS = 'RESERVATIONS',
  ATTENDANCE = 'ATTENDANCE',
  MESSAGES = 'MESSAGES',
  NOTES = 'NOTES',
}

@Component({
  selector: 'app-customer-card-modal',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, TranslateModule,
    ProgressSpinnerModule, TabViewModule, BadgeModule, TableModule, ButtonModule, InputTextModule, ChipsModule, SkeletonModule,
    ModalHeaderComponent, CustomerReservationTableRowComponent, CustomerCardOrderComponent, PricePipe, HiddenInputWrapComponent, CustomerCardCustomerComponent, CustomerCardAttendanceComponent, CustomerCardNotesComponent, CustomerCardMessagesComponent,
    PortalModule,
  ],
  templateUrl: './admin-customer-card-modal.component.html',
  styleUrls: ['./admin-customer-card-modal.component.scss']
})
export class AdminCustomerCardModalComponent extends Modal implements OnInit, OnDestroy {
  DataType = DataType;
  RoleType = RoleType;

  @Input() customerId: number | undefined;
  @Input() set activeTab(val: AdminCustomerCardTab) {
    if (val === AdminCustomerCardTab.CUSTOMER) this.activeTabIndex = 0;
    else if (val === AdminCustomerCardTab.ORDERS) this.activeTabIndex = 1;
    else if (val === AdminCustomerCardTab.RESERVATIONS) this.activeTabIndex = 2;
    else if (val === AdminCustomerCardTab.ATTENDANCE) this.activeTabIndex = 3;
    else if (val === AdminCustomerCardTab.MESSAGES) this.activeTabIndex = 4;
    else if (val === AdminCustomerCardTab.NOTES) this.activeTabIndex = 5;
  }

  footerPortal: Portal<any> | null = null;

  state: ICustomerCardState | undefined;

  activeTabIndex: number = 0;
  
  fetchingMagicCodeUuid: boolean = false;

  @Input() activeOrderNumbers: number[] = [];
  @Input() filteredOrderNumbers: number[] = [];


  subs: Subscription[] = [];

  constructor(
    private customerCardStore: CustomerCardStoreService,
    private selectedOrg: SelectedOrgService,
    private orgStore: OrganizationStoreService,
    private navService: NavigationService,
    private magicCodesService: MagicCodesService,
    private utilsService: UtilsService,
    public userStore: UserStoreService,
  ) {
    super();

  }

  ngOnInit(): void {
    this.state = this.customerCardStore.state;
    this.init();

  }

  init() {
    const orgId = this.selectedOrg.getCurrentValue();
    if (!this.customerId || !orgId) return;

    this.customerCardStore.init(this.customerId, orgId, CustomerCardInvoker.ADMIN);
  }

  onTabChange(index: any) {
    this.activeTabIndex = index;
  }
  filterGlobal(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value.trim(), 'contains');
  }

  onOpenCustomerPage(userId: number) {
  
    const tab = window.open('', '_blank');
    tab?.document.write('Loading...');

    this.fetchingMagicCodeUuid = true;
    this.magicCodesService.getForCustomer({ userId }).pipe(
      take(1),
      finalize(() => this.fetchingMagicCodeUuid = false)
    ).subscribe({
      next: async (magicCodeUuid) => {
        const org = this.orgStore.state.org$.value;
        if (!org) return;
        const url = await this.navService.goToCustomerOrdersInOrganization({
          magicCodeUuid: magicCodeUuid,
          orgId: org.id,
          returnUrl: true,
          orgCustomDomains: org.customDomainsVerified
        }) as string;

        if (tab?.location) tab.location = url;
      },
      error: (err) => {
        this.fetchingMagicCodeUuid = false;
        tab?.close();
      }
    })
  }

  

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
