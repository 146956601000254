
<div class="flex align-items-center justify-content-center"
  [class.with-select]="ref.children.length"
  [class.selected]="isSelected">

  <span #ref class="select-wrap">
    <ng-content select="[slot=select]">
    
    </ng-content>
  </span>

  <span
    [class.checked]="fullyCheckedIn || partiallyCheckedIn"
    [class.checkedin-full]="fullyCheckedIn"
    [class.checkedin-partial]="partiallyCheckedIn"
    class="index-wrap">
    {{ rowIndex !== undefined ? rowIndex + 1 : null }}
  </span>

</div>

