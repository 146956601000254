


<ng-container *ngIf="(state.fetchingUserData$ | async); else notFetching">
  <div class="flex justify-content-center align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-container>

<ng-template #notFetching>
  <ng-container *ngIf="(state.customerCard$ | async)?.userData as userData">
    
    <div style="flex: 1 1 auto;">

      <app-custom-data-inputs [customDataFG]="customDataFG" [userData]="userData"></app-custom-data-inputs>

    </div>
    
    

    <button
      *ngIf="customDataFG.dirty && !customDataFG.disabled"
      (click)="onSaveCustomData()"
      [loading]="!!(state.updatingUserData$ | async)"
      pButton
      label="Uložit">
    </button>

  </ng-container>
</ng-template>