import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IMessage } from 'src/app/shared/models/message/message.model';

export interface IMessagesGetForCustomerCardData {
  userId: number;
  orgId: number;

  page: number;
  pageSize: number;
}
export type IMessagesGetForCustomerCardResult = (IMessage & {
  senderUser: {
    name: string | null;
    surname: string | null;
    email: string | null;
  } | null;
  events: {
    id: number;
    uuid: string;
    code: string | null;
  }[];
  orders: {
    id: number;
    randomNumber: number;
    uuid: string;
  }[];
  reservations: {
    id: number;
  }[];
});

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    private dbService: DbService
  ) { }

  public getForUserCard(data: IMessagesGetForCustomerCardData): Observable<IMessagesGetForCustomerCardResult[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesMessagesGetForCustomerCard, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
