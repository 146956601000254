import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaymentGatePaymentAction, PaymentGatePaymentStatus, PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID, PAYMENT_GATE_REDIRECT_IFRAME_STATUS_ID } from 'src/app/pages/payment-gate-redirect/payment-gate-redirect.module';
import { AuthComponentState } from '../components/auth/auth.component';
import { SessionLecturerType } from '../enums/session-group/session-lecturer-types.enum';
import { RoleType } from '../enums/user/role-types.enum';
import { Operation } from '../enums/utils/operations.enum';
import { IEvent } from '../models/event/event.model';
import { ILocation } from '../models/location/location.model';
import { IOrganization, IOrganizationCustomMemberFormSettings } from '../models/organization/organization.model';
import { ISessionGroup } from '../models/session-group/session-group.model';
import { ISessionLecturer } from '../models/session-group/session-lecturer.model';
import { ISession } from '../models/session-group/session.model';
import { IUser } from '../models/user/user.model';
import { IPaymentInstructions } from './entities/payments/payments.service';
import { DeviceSize, SystemService } from './system.service';
import { IDiscountTemplate } from '../models/discount/discount-template.model';
import { FormControl, FormGroup } from '@angular/forms';
import { IInvoiceProfile } from '../models/invoice-profile/invoice-profile.model';
import { IBrandProfile } from '../models/brand-profile/brand-profile.model';
import { INextBestOfferTemplate } from '../models/next-best-offer-template/next-best-offer-template.model';
import { IOrder, IOrderFE, IOrderItemFE } from '../models/order/order-model';
import { IDiscountSummary } from '../models/reservation/reservations-summary.model';
import { IFormFieldSettings } from '../models/shop-item/form-field-settings.model';
import { CollectingFrom } from '../enums/collecting-data/collecting-from.enum';
import { IOffer } from '../models/offer/offer.model';
import { IShopItem } from '../models/shop-item/shop-item.model';
import { IInvoice } from '../models/invoice/invoice.model';
import { IAddressFG } from '../modals/address-form-group-modal/address-form-group-modal.component';
import { IReservation } from '../models/reservation/reservation.model';
import { ICancelReservationModalResult } from '../modals/cancel-reservation-modal/cancel-reservation-modal.component';
import { IShopItemInstallmentsSettings } from '../models/shop-item/shop-item-installments-settings.model';
import { IPrice } from '../models/price/price.model';
import { CloseMonthAttendanceModalComponent } from 'src/app/pages/admin/org-admin/attendance/lessons-overview/close-month-attendance-modal/close-month-attendance-modal.component';
import { FindCustomerModalResult } from '../modals/find-customer-modal/find-customer-modal.component';
import { AdminCustomerCardTab } from 'src/app/pages/admin/org-admin/components/modals/customer-card-modal/admin-customer-card-modal.component';
import { LecturerCustomerCardTab } from 'src/app/pages/admin/org-admin/components/modals/lecturer-customer-card-modal/lecturer-customer-card-modal.component';
import { IOrderItemPriceUpdateData } from './entities/order-items/order-items.service';
import { Currency } from '../enums/price/currencies.enum';
import { IOrderExpirationDatesModalResult } from 'src/app/pages/admin/org-admin/components/modals/order-expiration-dates-modal/order-expiration-dates-modal.component';
import { IApplicableDiscount } from '../models/discount/applicable-discount.model';
import { IAccreditationTemplate } from '../models/accreditation/accreditation-template.model';
import { ICategory } from '../models/category/category.model';
import { ICategoryGroup } from '../models/category/category-group.model';
import { IAddOrgUserInitValues } from '../modals/org-member-modal/org-member-modal.component';
import { ILecturer } from '../models/user/lecturer/lecturer.model';
import { ILocket } from '../models/locket/locket.model';
import { IStartAtEndAtFG } from 'src/app/pages/admin/org-admin/components/modals/session-card-modal/start-at-end-at-input/start-at-end-at-input.component';
import { ILocationPickerModalResult } from 'src/app/pages/admin/org-admin/components/modals/location-picker-modal/location-picker-modal.component';
import { INotificationTemplateModalInput } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-modules/shop-item-module-automatic-communication/notification-template-modal/notification-template-modal.component';
import { IMessageTemplate } from './entities/message-template/message-template-service';
import { ICertificateTemplate } from '../models/certificate/certificate-template.model';
import { IPdfDocumentDefinition } from '../models/certificate/certificate.model';
import { SessionCardTab } from 'src/app/pages/admin/org-admin/components/modals/session-card-modal/session-card-modal.component';
import { IAttendanceDocumentTemplate } from '../models/attendance-document-template/attendance-document-template.model';
import { CustomTableOrigin, ICustomTableColumnItem, ICustomTableGroupItem } from 'src/app/pages/admin/org-admin/reservations/reservation-table/custom-table-columns-modal/custom-table-columns-modal.component';
import { IPublicTag } from '../models/public-tag/public-tag.model';
import { IPublicTagGroup } from '../models/public-tag/public-tag-group.model';
import { IItemsFilterModalResult } from 'src/app/pages/admin/org-admin/reservations/reservation-table/items-filter-modal/items-filter-modal.component';
import { IReservationTableFilterItem } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filters.component';
import { IEventShopItemFilterItem } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/event-shop-items-filter.component';
import { IInternalTagGroup } from '../models/internal-tag/internal-tag-group.model';
import { IInternalTag } from '../models/internal-tag/internal-tag.model';

interface IBasicModalData {
  title: string;
  text: string;
  leftBtnLabel: string;
  leftBtnLabelData: any;
  leftBtnClass: string;
  leftBtnAction: () => void;
  rightBtnLabel: string;
  rightBtnLabelData: any;
  rightBtnClass: string;
  rightBtnAction: () => void;
}

export enum RecordPaymentModalType {
  RECORD_PAYMENT,
  PAIR_PAYMENT
}

interface IOpenRecordPaymentModalData {
  user?: IUser;
  orderId?: number | null;
  userName?: string;
  unpairedIncomeTransferId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalService: NgbModal,
    private sysService: SystemService,
  ) { }

  public openReservationTableFiltersModal(c: any, origin: CustomTableOrigin, selectedFilterItems: IReservationTableFilterItem[]) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.origin = origin;
    ref.componentInstance.selectedFilterItems = selectedFilterItems;
  }
  
  public openInternalTagGroupModal({
    c,
    operation,
    onChange,
    internalTagGroup
  }: {
    c: any,
    operation: Operation,
    onChange: () => void,
    internalTagGroup?: IInternalTagGroup
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;

    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.internalTagGroup = internalTagGroup;
  }

  public openInternalTagModal({
    c,
    operation,
    onChange,
    tag,
    toInternalTagGroupId
  }: {
    c: any,
    operation: Operation,
    onChange: () => void,
    tag?: IInternalTag,
    toInternalTagGroupId?: number | null
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.tag = tag;
    ref.componentInstance.selectedTagGroupId = toInternalTagGroupId;
  }

  public openSiInternalTagsModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openSessionAttendanceDocumentTemplateSelectModal(c: any, orgUuid: string, initTemplateId: number | null) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.orgUuid = orgUuid;
    ref.componentInstance.initTemplateId = initTemplateId;

    return ref.componentInstance.result as Subject<IAttendanceDocumentTemplate | null>;
  }

  public openPublicTagGroupModal({
    c,
    operation,
    onChange,
    publicTagGroup
  }: {
    c: any,
    operation: Operation,
    onChange: () => void,
    publicTagGroup?: IPublicTagGroup
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.publicTagGroup = publicTagGroup;
  }

  public openPublicTagModal({
    c,
    operation,
    onChange,
    tag,
    toPublicTagGroupId
  }: {
    c: any,
    operation: Operation,
    onChange: () => void,
    tag?: IPublicTag,
    toPublicTagGroupId?: number | null
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.tag = tag;
    ref.componentInstance.selectedTagGroupId = toPublicTagGroupId;
  }

  public openSiPublicTagsModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openShopItemCertificateIssueModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openHtmlPdfPreviewModal(c: any, docDefinition: IPdfDocumentDefinition) {
    const ref = this.modalService.open(c, {
      windowClass: 'modal-max-height'
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.docDefinition = docDefinition;
  }

  public openCertificateTemplateSelectModal(c: any, orgUuid: string, initTemplateId: number | null) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.orgUuid = orgUuid;
    ref.componentInstance.initTemplateId = initTemplateId;

    return ref.componentInstance.result as Subject<ICertificateTemplate | null>;
  }

  public openChooseEventToInstanciateToModal(c: any, orgId: number, currentCurrencies: Currency[], initEventId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.orgId = orgId;
    ref.componentInstance.currentCurrencies = currentCurrencies;
    ref.componentInstance.initEventId = initEventId;

    return ref.componentInstance.result as Subject<number>;
  }

  public openMessageTemplatePreviewModal(c: any, messageTemplate: IMessageTemplate) {
    const ref = this.modalService.open(c, {
      size: 'lg'
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.messageTemplate = messageTemplate;
  }

  public openNotificationTemplate(c: any, input: INotificationTemplateModalInput) {
    const ref = this.modalService.open(c, {
      windowClass: 'modal-max-height',
      size: 'lg'
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.input = input;
  }

  public openShopItemMissingCodeModal(c: any): Subject<string> {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;

    return ref.componentInstance.result;
  }

  public openLocationPickerModal(c: any, datesFG: FormGroup<IStartAtEndAtFG>, locationIdFC: FormControl<number | null>, session: ISession, shopItem: IShopItem) {
    const deviceSize = this.sysService.deviceSize$.getValue();

    const ref = this.modalService.open(c, {
      size: 'xl',
      windowClass: [ DeviceSize.SM, DeviceSize.MD ].includes(deviceSize) ? '' : 'modal-max-height',
      fullscreen: [ DeviceSize.SM, DeviceSize.MD ].includes(deviceSize) ? true : false
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.datesFG = datesFG;
    ref.componentInstance.session = session;
    ref.componentInstance.shopItem = shopItem;
    ref.componentInstance.locationIdFC = locationIdFC;

    return ref.componentInstance.result as ILocationPickerModalResult;
  }

  public openNewCustomerModal(c: any) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });

    ref.componentInstance.ref = ref;
  }

  public openEventShopItemFilterTogglerModal(c: any, selectedFilterItems: IEventShopItemFilterItem[]) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });

    ref.componentInstance.ref = ref;
    ref.componentInstance.selectedFilterItems = selectedFilterItems;
  }

  public openOrgUsersFilterTogglerModal(c: any, customMemberFormSettings: IOrganizationCustomMemberFormSettings[]) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });

    ref.componentInstance.ref = ref;
    ref.componentInstance.customMemberFormSettings = customMemberFormSettings;
  }

  public openLecturerPickerModal(c: any, lecturers$: BehaviorSubject<(ILecturer & { user: IUser & { lockets: ILocket[] } })[]>, orgId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.lecturers$ = lecturers$;
    ref.componentInstance.orgId = orgId;

    return ref.componentInstance.selectedLecturerIds as Subject<number[]>;
  }

  public openSiCoordinatorsModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openSessionGroupLecturerHourRateModal(c: any, sessionGroupLecturerId: number, hourRate: IPrice | null) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.sessionGroupLecturerId = sessionGroupLecturerId;
    ref.componentInstance.hourRate = hourRate;
  }

  public openSessionCopyModal(c: any, sessionId: number) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.sessionId = sessionId;
  }

  public openSiLecturersModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openCategoryGroupModal({
    c,
    operation,
    onChange,
    categoryGroup
  }: {
    c: any,
    operation: Operation,
    onChange: () => void,
    categoryGroup?: ICategoryGroup
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.categoryGroup = categoryGroup;
  }

  public openCategoryModal({
    c,
    operation,
    onChange,
    category,
    toCategoryGroupId
  }: {
    c: any,
    operation: Operation,
    onChange: () => void,
    category?: ICategory,
    toCategoryGroupId?: number | null
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.category = category;
    ref.componentInstance.selectedCategoryGroupId = toCategoryGroupId;
  }

  public openSiCategoriesModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openAccreditationTemplateModal(c: any, operation: Operation, onChange: () => void, accreditationTemplate?: IAccreditationTemplate) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.onChange = onChange;
    ref.componentInstance.accreditationTemplate = accreditationTemplate;
  }

  public openShopItemAccreditationModal(c: any, shopItemId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openShopItemNotesModal(c: any, shopItemUuid: string) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemUuid = shopItemUuid;
  }

  public openOrgCustomDomainClientSectionModal(c: any) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
  }

  public openNewAppVersionModal(c: any) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
  }

  public openOrderExpirationDatesModal(c: any, maturity: string, expiration: string): Observable<IOrderExpirationDatesModalResult> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.maturity = maturity;
    ref.componentInstance.expiration = expiration;

    return ref.componentInstance.choosenDates;
  }
  
  public openSiApplicableDiscountsModal(c: any, shopItemId: number, eventId: number, applicableDiscounts: IApplicableDiscount[]) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
    ref.componentInstance.eventId = eventId;
    ref.componentInstance.shopItemApplicableDiscounts = applicableDiscounts;
  }

  public openReservationsTableItemsFilterModal(c: any, initValues: IItemsFilterModalResult | null, eventUuid: string | null) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });

    ref.componentInstance.ref = ref;
    ref.componentInstance.initValues = initValues;
    ref.componentInstance.eventUuid = eventUuid;
    return ref.componentInstance.result as Subject<IItemsFilterModalResult>;
  }

  public openSessionCardModal({
    c,
    sessionUuid,
    activeTab
  }: {
    c: any,
    sessionUuid: string,
    activeTab?: SessionCardTab,
  }) {
    const ref = this.modalService.open(c, {
      modalDialogClass: 'session-card-modal',
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.sessionUuid = sessionUuid;
    ref.componentInstance.activeTab = activeTab;
  }
  
  public openTicketsSendModal(c: any, shopItemId: number | null, eventId: number | null) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });

    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
    ref.componentInstance.eventId = eventId;
  }

  public openChangeReservationShopItemModal(c: any, reservationId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.reservationId = reservationId;
  }

  public openReservationDetailModal(c: any, reservationId: number) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.reservationId = reservationId;
  }

  public openFindCustomerModal(c: any) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;

    return ref.componentInstance.result as FindCustomerModalResult;
  }

  public openMySessionGroupItemModal(c: any, shopItem: IShopItem) {
    const ref = this.modalService.open(c, {
      size: 'md',
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItem = shopItem;
  }

  public openCloseMonthAttendanceModal(c: any) {
    const ref = this.modalService.open(c, {
      size: 'md',
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    

    return (<CloseMonthAttendanceModalComponent>ref.componentInstance).result;
  }

  public openSessionAttendanceModal(c: any, sessionUuid: string) {
    const ref = this.modalService.open(c, {
      size: 'md',
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.sessionUuid = sessionUuid;
  }

  public openStateChangeConfirmModal(c: any, reservationsToCancel: IReservation[]) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.reservationsToCancel = reservationsToCancel;
    return ref.componentInstance.result as Subject<any>;
  }

  public openReservationTableColumnsModal(c: any, selectedColumnItems: ICustomTableColumnItem[], origin: CustomTableOrigin, groups: ICustomTableGroupItem[], groupName: string, onExport: boolean = false) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.selectedColumnItems = selectedColumnItems;
    ref.componentInstance.origin = origin;
    ref.componentInstance.groups = groups;
    ref.componentInstance.groupName = groupName;
    ref.componentInstance.onExport = onExport;

    return ref.componentInstance.result;
  }

  public openMassMailModal({
    c, reservations, subject, body
  }: {
    c: any, reservations: IReservation[], subject?: string | null, body?: string | null
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.reservations = reservations;
    ref.componentInstance.subject = subject;
    ref.componentInstance.body = body;
  }

  public openAddressFormGroupModal(c: any, FG: FormGroup<IAddressFG>) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.FG = FG;

    return ref.componentInstance.result;
  }

  public openInvoiceModifyModal(c: any, invoice: IInvoice, order: IOrder | IOrderFE, proForma: boolean, creditNoteExists: boolean, issueNewInvoice: boolean = false) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-2x-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.invoice = invoice;
    ref.componentInstance.order = order;
    ref.componentInstance.proForma = proForma;
    ref.componentInstance.creditNoteExists = creditNoteExists;
    ref.componentInstance.issueNewInvoice = issueNewInvoice;

    return ref.componentInstance.modified;
  }

  public openOfferReservationCustomData(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }
  public openOfferItemModal(c: any, offer: IOffer, item: IShopItem) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
    ref.componentInstance.item = item;
  }
  public openOfferAddContactUserModal(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }
  public openOfferUserChangeModal(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }
  public openOfferAddItemModal(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }
  public openOfferIssueModal(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }
  public openOfferStateModal(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }
  public openOfferAccompanyingTextModal(c: any, offer: IOffer) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.offer = offer;
  }

  public openInvoicesExportModal(c: any, invoiceUuids: string[], dateRangeFilter?: (Date | null)[]) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.invoiceUuids = invoiceUuids;
    ref.componentInstance.dateRangeFilter = dateRangeFilter;
  }

  public openDownloadModal(c: any, downloadUrl$: BehaviorSubject<string | null>, preparingLink$: BehaviorSubject<boolean>) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.downloadUrl$ = downloadUrl$;
    ref.componentInstance.preparingLink$ = preparingLink$;
  }

  public openChooseExpirationModal(c: any, currentDatetime: string | null) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.currentDatetime = currentDatetime;

    return ref.componentInstance.chosenDatetime;
  }

  public openOrderItemPriceModal(c: any, orderItemId: number, reservationId: number, basePriceValue: number, currency: Currency, saveAction: (d: IOrderItemPriceUpdateData) => Observable<any>) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.orderItemId = orderItemId;
    ref.componentInstance.reservationId = reservationId;
    ref.componentInstance.basePriceValue = basePriceValue;
    ref.componentInstance.currency = currency;
    ref.componentInstance.saveAction = saveAction;
  }

  public openOrderItemDiscountModal(c: any, reservationId: number, discount: IDiscountSummary, orderId: number) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.reservationId = reservationId;
    ref.componentInstance.discountTemplateId = discount.id;
    ref.componentInstance.percentValue = discount.percentValue;
    ref.componentInstance.orderId = orderId;
  }

  public openLecturerCustomerCardModal({
    c,
    userId,
    activeTab
  }: {
    c: any,
    userId: number,
    activeTab?: LecturerCustomerCardTab,
  }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right modal-full-right--lg',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.customerId = userId;
    ref.componentInstance.activeTab = activeTab;
  }

  public openAdminCustomerCardModal(
    {
      c,
      userId,
      fromOrderNumbers = [],
      activeTab = AdminCustomerCardTab.ORDERS,
    }: {
      c: any,
      userId: number,
      fromOrderNumbers?: number[]
      activeTab?: AdminCustomerCardTab,
    }) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right modal-full-right--lg',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.customerId = userId;
    ref.componentInstance.activeOrderNumbers = fromOrderNumbers;
    ref.componentInstance.filteredOrderNumbers = fromOrderNumbers;
    ref.componentInstance.activeTab = activeTab;
  }

  public openNextBestOfferTemplateModal(c: any, operation: Operation, eventId: number, nextBestOfferTemplate?: INextBestOfferTemplate) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.nextBestOfferTemplate = nextBestOfferTemplate;
    ref.componentInstance.eventId = eventId;
  }

  public openDiscountTemplateModal(c: any, operation: Operation, eventId: number | null, shopItemId: number | null, onClose?: () => void, discountTemplate?: IDiscountTemplate) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.discountTemplate = discountTemplate;
    ref.componentInstance.eventId = eventId;
    ref.componentInstance.onClose = onClose;
    ref.componentInstance.shopItemId = shopItemId;
  }

  public openFormSettingsModal(c: any, shopItemId: number, collectingFrom: CollectingFrom): Observable<{ formFieldSettings: IFormFieldSettings[] }> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.shopItemId = shopItemId;
    ref.componentInstance.collectingFrom = collectingFrom;

    return ref.componentInstance.result;
  }

  public openFormFieldSettingsModal(c: any, operation: Operation, shopItemId: number, collectingFrom: CollectingFrom, name: string | null, formFieldSettings: IFormFieldSettings | null, collectedUserData: boolean = false): Observable<{ formFieldSettings: IFormFieldSettings }> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.shopItemId = shopItemId;
    ref.componentInstance.name = name;
    ref.componentInstance.collectingFrom = collectingFrom;
    ref.componentInstance.formFieldSettings = formFieldSettings;
    ref.componentInstance.collectedUserData = collectedUserData;

    return ref.componentInstance.result;
  }

  public openCancelReservationModal(c: any, reservation: IReservation, order: IOrder | null): Observable<ICancelReservationModalResult> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.reservation = reservation;
    ref.componentInstance.order = order;

    return ref.componentInstance.result;
  }

  public openInstallmentsModal(c: any, installmentsSettings: IShopItemInstallmentsSettings | null, prices: IPrice[]): Observable<IShopItemInstallmentsSettings> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.installmentsSettings = installmentsSettings;
    ref.componentInstance.prices = prices;

    return ref.componentInstance.result;
  }

  public openBrandProfileModal(c: any, orgId: number, selectedBrandProfile: IBrandProfile | null, eventId?: number, offerId?: number): Observable<{ brandProfile: IBrandProfile }> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.orgId = orgId;
    ref.componentInstance.selectedBrandProfile = selectedBrandProfile;
    ref.componentInstance.eventId = eventId;
    ref.componentInstance.offerId = offerId;

    return ref.componentInstance.result;
  }

  public openSetBrandProfileModal(c: any, operation: Operation, selectedBrandProfile: IBrandProfile | null, eventId?: number, offerId?: number): Observable<{ brandProfile: IBrandProfile }> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.selectedBrandProfile = selectedBrandProfile;
    ref.componentInstance.eventId = eventId;
    ref.componentInstance.offerId = offerId;

    return ref.componentInstance.result;
  }

  public openInvoiceProfileModal(c: any, operation: Operation, invoiceProfile: IInvoiceProfile | null): Observable<{ invoiceProfile: IInvoiceProfile }> {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      size: 'md',
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.invoiceProfile = invoiceProfile;

    return ref.componentInstance.result;
  }

  public openPaymentModal(c: any, paymentInstructions: IPaymentInstructions, orderUuid: string, companyOrder: boolean, refreshState$: Subject<void>) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      backdrop: 'static',
      windowClass: [DeviceSize.SM].includes(deviceSize) ? 'modal-down' : '',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.paymentInstructions = paymentInstructions;
    ref.componentInstance.orderUuid = orderUuid;
    ref.componentInstance.companyOrder = companyOrder;

    // handle messages from iframe (works with comgate for now)
    let listened = false;
    const iframeListenerFnc = (e: MessageEvent<any>) => {
      if (typeof e.data === 'string') {
        // listen payment status
        if (e.data.includes(PAYMENT_GATE_REDIRECT_IFRAME_STATUS_ID)) {
          const d = e.data.split('|');
          const id = d[0];
          const PG_CODE = d[1];
          const paymentStatus = <PaymentGatePaymentStatus>d[2];

          if (Object.keys(PaymentGatePaymentStatus).includes(paymentStatus)) {
            refreshState$.next();
          }

          switch (paymentStatus) {
            case PaymentGatePaymentStatus.CANCELLED:
            case PaymentGatePaymentStatus.PENDING:
              ref.close();
              break;
            case PaymentGatePaymentStatus.PAID:
              break;
            default:
              break;
          }
        }

        // listen action
        if (e.data.includes(PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID)) {
          if (listened) return;
          listened = true;
          const d = e.data.split('|');
          const id = d[0];
          const action = <PaymentGatePaymentAction>d[1];

          if (action === PaymentGatePaymentAction.PAY) {
            ref.close();
            // pay again = reopen modal
            this.openPaymentModal(c, paymentInstructions, orderUuid, companyOrder, refreshState$);
          } else if (action === PaymentGatePaymentAction.FINISH) {
            ref.close();
          } else {
            console.error('Not implemented!, 0930wejo');
          }
        }
      }
    }
    window.addEventListener('message', iframeListenerFnc, false);

    ref.result.finally(() => {
      window.removeEventListener('message', iframeListenerFnc);
    });

  }

  public openRecordPaymentModal(c: any, data?: IOpenRecordPaymentModalData, type: RecordPaymentModalType = RecordPaymentModalType.RECORD_PAYMENT) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.user = data?.user;
    ref.componentInstance.orderId = data?.orderId;
    ref.componentInstance.userName = data?.userName;
    ref.componentInstance.unpairedIncomeTransferId = data?.unpairedIncomeTransferId;
    ref.componentInstance.modalType = type;
  }

  public openNewShopItemModal(c: any, event: IEvent) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.event = event;
  }

  public openBasicModal(c: any, data: IBasicModalData) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.title = data.title;
    ref.componentInstance.text = data.text;
    ref.componentInstance.leftBtnLabel = data.leftBtnLabel;
    ref.componentInstance.leftBtnLabelData = data.leftBtnLabelData;
    ref.componentInstance.leftBtnClass = data.leftBtnClass;
    ref.componentInstance.leftBtnAction = data.leftBtnAction;
    ref.componentInstance.rightBtnLabel = data.rightBtnLabel;
    ref.componentInstance.rightBtnLabelData = data.rightBtnLabelData;
    ref.componentInstance.rightBtnClass = data.rightBtnClass;
    ref.componentInstance.rightBtnAction = data.rightBtnAction;
  }

  public openConfirmModal(
    { c, title, text, cancelBtnLabel = 'admin.confirm-modal.close', confirmBtnLabel = 'admin.confirm-modal.confirm', template }:
    { c: any, title: string, text: string, cancelBtnLabel?: string, confirmBtnLabel?: string, template?: TemplateRef<any> }
  ): Observable<boolean | null> {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.title = title;
    ref.componentInstance.text = text;
    ref.componentInstance.cancelBtnLabel = cancelBtnLabel;
    ref.componentInstance.confirmBtnLabel = confirmBtnLabel;
    ref.componentInstance.template = template;

    return ref.componentInstance.result;
  }

  public openConfirmDeleteModal(
    { c, title, text, textData, cancelBtnLabel = 'admin.confirm-delete-modal.close', deleteBtnLabel = 'admin.confirm-delete-modal.remove', template }:
    { c: any, title: string, text: string, cancelBtnLabel?: string, deleteBtnLabel?: string, template?: TemplateRef<any>, textData?: any }
  ): Observable<boolean> {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.title = title;
    ref.componentInstance.text = text;
    ref.componentInstance.textData = textData;
    ref.componentInstance.cancelBtnLabel = cancelBtnLabel;
    ref.componentInstance.deleteBtnLabel = deleteBtnLabel;
    ref.componentInstance.template = template;

    return ref.componentInstance.result;
  }

  public openOrgFillMissingDataModal(c: any, orgId: number, missingFields: (keyof IOrganization)[]) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.orgId = orgId;
    ref.componentInstance.missingFields = missingFields;
  }

  public openAuthModal(
    {c, title, state, initEmail, setRedirectAfterLoad}:
    {c: any, title: string, state?: AuthComponentState, initEmail?: string, setRedirectAfterLoad?: boolean}) {

    const ref = this.modalService.open(c, { size: 'sm' });
    ref.componentInstance.ref = ref;
    ref.componentInstance.title = title;
    ref.componentInstance.state = state;
    ref.componentInstance.initEmail = initEmail;
    ref.componentInstance.setRedirectAfterLoad = setRedirectAfterLoad;

    return ref.componentInstance.signedIn as Observable<boolean>;
  }

  public openSessionLecturerAttendanceModal(c: any, type: SessionLecturerType, session: ISession | null, sessionLecturer: ISessionLecturer | null) {
    const deviceSize = this.sysService.deviceSize$.getValue();
    const ref = this.modalService.open(c, {
      size: 'md',
      windowClass: deviceSize === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.session = session;
    ref.componentInstance.type = type;
    ref.componentInstance.sessionLecturer = sessionLecturer;
  }

  public openLocationModal({ c, operation, location }: { c: any, operation: Operation, location?: ILocation }): Observable<{operation: Operation, data: ILocation}> {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right modal-full-right--lg',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.location = location;

    return ref.componentInstance.result;
  }

  public openOrgUserRolesModal(c: any, user: IUser, orgId: number) {
    const ref = this.modalService.open(c, {});
    ref.componentInstance.ref = ref;
    ref.componentInstance.user = user;
    ref.componentInstance.orgId = orgId;
  }

  public openOrgMemberModal(
    { c, toOrganizationId, operation, userId, preselectedRoles = [], initValues = {} }:
    { c: any, toOrganizationId: number, operation: Operation, userId?: number, preselectedRoles?: RoleType[], initValues?: IAddOrgUserInitValues}) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right modal-full-right--lg',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.toOrganizationId = toOrganizationId;
    ref.componentInstance.preselectedRoles = preselectedRoles;
    ref.componentInstance.operation = operation;
    ref.componentInstance.userId = userId;
    ref.componentInstance.initValues = initValues;

    return ref;
  }

  public openSessionGroupModal(
    {c, operation, eventId, event, sessionGroupId, sessionGroup}:
    {c: any, operation: Operation, eventId?: number, event?: IEvent, sessionGroupId?: number, sessionGroup?: ISessionGroup})
    : Observable<ISessionGroup>
  {
    const deviceSize = this.sysService.deviceSize$.getValue();

    const ref = this.modalService.open(c, {
      size: 'lg',
      backdrop: 'static',
      fullscreen: [ DeviceSize.SM, DeviceSize.MD ].includes(deviceSize) ? true : false
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.operation = operation;
    ref.componentInstance.eventId = eventId;
    ref.componentInstance.event = event;
    ref.componentInstance.sessionGroupId = sessionGroupId;
    ref.componentInstance.sessionGroup = sessionGroup;

    return ref.componentInstance.changed;
  }

  public openNewEventModal({
    c,
    prefillTitle,
    prefillFormat,
  }: {
    c: any,
    prefillTitle?: string,
    prefillFormat?: string,
  }) {
    const ref = this.modalService.open(c, {
      windowClass: 'modal-max-height',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.prefillTitle = prefillTitle;
    ref.componentInstance.prefillFormat = prefillFormat;

    return ref.componentInstance.eventCreated;
  }
}
