import { ShopItemCancellationType } from "../../enums/shop-item/shop-item-cancellation-type.enum";
import { ShopItemInvoicingMode } from "../../enums/shop-item/shop-item-invoicing-mode.enum";
import { ShopItemPublicationType } from "../../enums/shop-item/shop-item-publication-types.enum";
import { ShopItemReservationMode } from "../../enums/shop-item/shop-item-reservation-mode.enum";
import { ShopItemReservationState } from "../../enums/shop-item/shop-item-reservation-states.enum";
import { ShopItemReservationType } from "../../enums/shop-item/shop-item-reservation-types.enum";
import { ShopItemState } from "../../enums/shop-item/shop-item-states.enum";
import { IAccreditation } from "../accreditation/accreditation.model";
import { IAttendanceDocumentTemplate } from "../attendance-document-template/attendance-document-template.model";
import { IAutomatRule } from "../automat-rule/automat-rule.model";
import { ICategory } from "../category/category.model";
import { ICertificateTemplate } from "../certificate/certificate-template.model";
import { IApplicableDiscount } from "../discount/applicable-discount.model";
import { IEvent } from "../event/event.model";
import { IImage } from "../image/image.model";
import { IInternalTag } from "../internal-tag/internal-tag.model";
import { IInvoiceProfile } from "../invoice-profile/invoice-profile.model";
import { INextBestOfferTemplate } from "../next-best-offer-template/next-best-offer-template.model";
import { INextBestOffer } from "../next-best-offer/next-best-offer.model";
import { IOrganization } from "../organization/organization.model";
import { IPrice } from "../price/price.model";
import { IPublicTag } from "../public-tag/public-tag.model";
import { IReservation } from "../reservation/reservation.model";
import { IScheduledTask } from "../scheduled-tasks/scheduled-task.model";
import { ISessionGroup } from "../session-group/session-group.model";
import { IFormFieldSettings } from "./form-field-settings.model";
import { IShopItemCustomSettings } from "./shop-item-custom-settings.model";
import { IShopItemInstallmentsSettings } from "./shop-item-installments-settings.model";
import { IShopItemSettingModule } from "./shop-item-setting-module.model";


export interface IShopItem {
  id: number;
  uuid: string;
  uiIndex: number;
  state: ShopItemState;
  publicationType: ShopItemPublicationType;
  reservationState: ShopItemReservationState;
  reservationType: ShopItemReservationType;
  approvalType: ShopItemApprovalType;
  cancellationType: ShopItemCancellationType;
  title: string;
  subtitle: string | null;
  code: string | null;
  shortDescription: string | null;
  description: string | null;
  price: IPrice[];
  customCategories: string[];
  keywords: string[];
  customFilterTags: string[];
  quantity: number;
  targetQuantity: number | null;
  minQuantity: number | null;
  invoiceTitle: string;
  imageUrls: IImage[];
  formSettings: IFormFieldSettings[];
  secondsToPay: number;
  secondsToOrder: number;
  secondsToMaturity: number;
  reservationMode: ShopItemReservationMode;
  invoicingMode: ShopItemInvoicingMode;
  installmentsSettings: IShopItemInstallmentsSettings | null;
  customSettings: IShopItemCustomSettings;
  vat: number | string; // TODO: ??? string from decimal?
  settingModules: IShopItemSettingModule[];
  // payOnSite: boolean; // unused..
  automatRules: IAutomatRule[];
  isTemplate: boolean;
  isRepresentative: boolean;
  customItemNumberTemplate: string | null;
  customItemNumber: string | null;
  itemNumberInYear: number | null;
  itemNumberInOrg: number | null;
  itemNumberInEvent: number | null;

  instanceOfId: number | null;
  instanceOf?: IShopItem | null;
  instances: IShopItem[];
  sessionGroup?: ISessionGroup | null;
  eventId: number;
  event?: IEvent;
  organizationId: number;
  organization?: IOrganization;
  applicableDiscounts?: IApplicableDiscount[];
  nextBestOfferTemplates?: INextBestOfferTemplate[];
  nextBestOffers?: INextBestOffer[];
  reservations?: IReservation[];
  invoiceProfile?: IInvoiceProfile;
  accreditations?: IAccreditation[];
  categories?: ICategory[];
  scheduledTasks?: IScheduledTask[];
  certificateTemplateId: number | null;
  certificateTemplate?: ICertificateTemplate;
  attendanceDocumentTemplateId: number | null;
  attendanceDocumentTemplate?: IAttendanceDocumentTemplate;
  publicTags?: IPublicTag[];
  internalTags?: IInternalTag[];

  archived: boolean;
  archivedAt: string | null;
  createdAt: string;
  updatedAt: string;

};


export enum ShopItemApprovalType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
};
