import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../models/user/user.model';
import { UserNameOrEmailPipe } from './user-name-or-email.pipe';

@Pipe({
  name: 'userInitials',
  standalone: true
})
export class UserInitialsPipe implements PipeTransform {

  constructor(
    private nameOrEmailPipe: UserNameOrEmailPipe
  ) {  }

  transform(user: Partial<IUser> | undefined): string {
    const x = this.nameOrEmailPipe.transform(user);
    const split = x.toUpperCase().split(' ');
    return `${split[0] ? split[0][0] : '' }${split[1] ? split[1][0] : ''}`;
  }

}
